[v-cloak] {
  display: none;
}

.input-margin {
  margin: 4px;
}

.modal.in .modal-dialog {
  top: 50vh;
  transform: translateY(-50%);
  margin: 0 auto;
}

.content-wrapper {
  position: relative;
}

.main-sidebar {
  z-index: auto;

  .user-panel .image {
    color: #fff;
  }
}

.media-icon-container {
  position: relative;
  width: 100%;
  padding-top: 100%;

  .media-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}


.dataTables_wrapper .row:first-child {
  display: none;
}

.ql-container {
  font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.ql-tooltip {
  z-index: 999;
}

.dropzone {
  border: 5px dashed lighten($text-muted, 20);
  transition: border .3s;

  .dz-default {
    font-size: 2em;
    color: lighten($text-muted, 20);
    transition: color .3s;
  }

  &:hover {
    border-color: $text-muted;

    .dz-default {
      color: $text-muted;
    }
  }

  .dz-preview {


    .dz-image {
      border-radius: 0;
    }

    .dz-details {
      top: 5px;
      left: 5px;
      right: 5px;
      min-width: initial;
      padding: 0;

      .dz-filename,
      .dz-size {
        margin-bottom: 29px;
        text-align: left;

        span {
          display: inline-block;
        }
      }
    }

    .dz-progress {
      top: initial;
      bottom: 15px;
      left: 15px;
      right: 15px;
      margin: 0;
      width: 90px;
      border-radius: 0;
    }

    .dz-remove {
      position: absolute;
      top: 5px;
      right: 5px;
      z-index: 20;
      color: transparent;
      font-size: 0;
      cursor: pointer;

      @extend .fa;
      @extend .fa-times;
      @extend .btn;
      @extend .btn-default;
      @extend .btn-sm;

      &:before {
        color: $text-color;
        font-size: $font-size-base;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.icheckbox_minimal-blue.indeterminate {
  background-position: -200px 0;
}

.permissions-container {
  columns: 4;

  .permission-node {
    page-break-inside: avoid;

    .form-group {
      margin-bottom: 5px;
    }

    blockquote {
      font-size: $font-size-base;
      font-weight: normal;
      padding-top: 5px;
      padding-bottom: 5px;
      margin: 0;
      margin-bottom: 5px;

      .form-group:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.checkbox-switch {
  display: none;

  // Defaults
  & + label {
    @extend .form-control;
    width: $input-height-base * 2;
    height: $input-height-base;
    padding: 1px;
    border-radius: $input-height-base / 2;
    cursor: pointer;

    &:before {
      @extend .btn;
      @extend .btn-default;
      content: '';
      width: $input-height-base - 4;
      height: $input-height-base - 4;
      border-radius: 50%;
      transition: margin .3s, background .3s, border .3s;
    }
  }

  // Checked
  &:checked + label:before {
    @extend .btn-primary;
    margin-left: $input-height-base;
  }

  // Small
  &.checkbox-switch-sm + label {
    width: $input-height-small * 2;
    height: $input-height-small;
    border-radius: $input-height-small / 2;

    &:before {
      width: $input-height-small - 4;
      height: $input-height-small - 4;
    }
  }
  &.checkbox-switch-sm:checked + label:before {
    @extend .btn-primary;
    margin-left: $input-height-small;
  }
}